<template>
  <div class="amap-page-containe">
    <el-amap-search-box class="search-box" :search-option="searchOption" :on-search-result="onSearchResult">
    </el-amap-search-box>
    <el-amap :center="mapCenter" :zoom="zoom" id="container" :vid="'amapDemo'" :events="events">
      <el-amap-marker v-if="markerCenter[0] || markerCenter[1]" :position="markerCenter"
        :label="{ content: locationAreaName, offset: [10, -10] }"></el-amap-marker>
    </el-amap>
  </div>
</template>

<script>
// import BaiduMap from "vue-baidu-map";
// import Vue from "vue";
// Vue.use(BaiduMap, {
//   /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
//   ak: "fIbMhwXWy25gKbypG85Sjk3ZSifwgyHa",
// })
import Vue from "vue";
import VueAMap from "vue-amap";
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "a4b3fe627cd306aa3d9e74d35fae62a5", //高度地图凭证
  plugin: ["Autocomplete", "PlaceSearch", "PolyEditor", "CircleEditor"],
  v: "1.4.4",
});
export default {
  props: {
    areaName: {
      type: String,
      default: "珠海市",
    },
    lng: {
      type: Number,
      default: 0,
    },
    lat: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    lng: {
      handler() {
        // this.markerPoint = { lng: this.lng, lat: this.lat };
        this.mapCenter = [
          this.lng ? this.lng : 116.397447,
          this.lat ? this.lat : 39.909178,
        ];
        this.markerCenter = [this.lng, this.lat];
        this.getLocationArea(this.markerCenter);
      },
      deep: true,
      immediate: true
    },
    lat: {
      handler() {
        // this.markerPoint = { lng: this.lng, lat: this.lat };
        this.mapCenter = [
          this.lng ? this.lng : 116.397447,
          this.lat ? this.lat : 39.909178,
        ];
        this.markerCenter = [this.lng, this.lat];
        this.getLocationArea(this.markerCenter);
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      locationAreaName: "详细地区",
      mapCenter: [116.397447, 39.909178],
      markerCenter: [0, 0],
      searchOption: {
        citylimit: false,
      },
      events: {
        click: (e) => {
          let _this = this;
          const { lng, lat } = e.lnglat;
          _this.getLocationArea([lng, lat]);
          _this.markerCenter = [lng ? lng : 116.397447, lat ? lat : 39.909178];
          _this.$emit("lngLatChange", {
            lng,
            lat,
          });
        },
      },
      zoom: 14,
      // markerPoint: null,
      // addressTitleShow: true,
    };
  },
  methods: {
    getLocationArea(lngLat) {
      let _this = this;
      if (lngLat.length > 0 && lngLat[0] && lngLat[1]) {
        AMap.plugin("AMap.Geocoder", function () {
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all",
          });
          geocoder.getAddress(lngLat, function (status, result) {
            _this.locationAreaName = result.regeocode
              ? result.regeocode.formattedAddress
              : "详情地区";
          });
        });
      }
    },
    onSearchResult(pois) {
      if (pois.length > 0) {
        let lng = pois[0].lng;
        let lat = pois[0].lat
        this.mapCenter = [lng, lat];
        this.markerCenter = [lng, lat];
        this.getLocationArea([lng, lat]);
        this.$emit("lngLatChange", {
          lng,
          lat,
        });
      }
    },
  },
  // AreaCodeFun(type, target, point, pixel, overlay) {
  //   this.markerPoint = type.point;
  //   this.addressTitleShow = true;
  //   this.$emit("lngLatChange",{
  //       lng:type.point.lng,
  //       lat:type.point.lat
  //   });
  // },
  // infoWindowClose() {
  //   this.addressTitleShow = false;
  // },
  // infoWindowOpen(type, target) {
  //   this.addressTitleShow = true;
  // },
  // },
};
</script>

<style>
.search-box {
  border: 1px solid #999;
}

.icon-location-pin {
  font-size: 20px;
}

.amap-page-container {
  position: relative;
}

#container {
  top: 0;
  width: 100%;
  height: 550px;
  border-radius: 4px;
  border: 1px solid #d2d6de;
}
</style>